import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import bytovka1 from '../assets/images/bytovka1.jpeg'
import bytovka2 from '../assets/images/bytovka2.jpeg'
import bytovka3 from '../assets/images/bytovka4.jpeg'
import bytovka4 from '../assets/images/bytovka5.jpeg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

const articles = [
    {
        pic: bytovka1,
        title: 'Бытовка',
        price: '17\'000',
        link: '/bytovka'
    },
    {
        pic: bytovka2,
        title: 'Вагончик',
        price: '25\'000',
        link: '/bytovka'
    },
    {
        pic: bytovka3,
        title: 'Вагончик',
        price: '13\'000',
        link: '/bytovka'
    },
    {
        pic: bytovka4,
        title: 'Бытовка',
        price: '15\'000',
        link: '/bytovka'
    },
];

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Виктор - Отделка, утепление балконов, обшивка вагонкой"
                    meta={[
                        { name: 'description', content: 'Отделка балконов, бань, саун и прочих помещений вагонкой, а так же утепление балконов. Низкие цены.' },
                        { name: 'keywords', content: 'ремонт балкона, обшивка вагонкой, цены' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        {articles.map(({ pic, title, price, link }) => 
                            <article style={{backgroundImage: `url(${pic})`}}>
                                <header className="major">
                                    <h3>{title}</h3>
                                    <p>Цена работ: {price} ₽</p>
                                </header>
                                <Link to={link} className="link primary"></Link>
                            </article>
                        )}
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Почему стоит обратиться <br/> именно ко мне?</h2>
                            </header>

                            <ul>
                                <li>Вы не платите зарплаты менеджеров по продажам, их директоров, затраты на маркетинг и прочие расходы крупной организации.</li>
                                <li>Вы звоните мне напрямую, а значит всю информацию ответственный исполнитель (то есть я) узнает из первых уст.</li>
                                <li>Я могу выполнить закупку и доставку товаров, предоставив все чеки.</li>
                                <li>Имея богатый опыт в отделке помещений вагонкой, я отвечу на все Ваши вопросы</li>
                            </ul>

                            <h3></h3>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex