import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Здравствуйте, меня зовут Виктор</h1>
            </header>
            <div className="content">
                <p>Я помогу Вам сделать ремонт на балконе <br/> или обшить вагонкой любое помещение.</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Смотреть работы</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
